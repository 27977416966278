



















































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { UserListObj, UserAddParams } from './types'
import { Form } from 'element-ui'

@Component({ name: 'UserList' })
export default class UserList extends VueBase {
  private page = 1
  private pageSize = 20
  private total = 0
  private keywords = ''
  private tableData: Array<UserListObj> = []
  private departments: Array<{
    id: number
    name: string
  }> = []
  private addDialogOpen = false
  private userForm: UserAddParams = {
    username: '',
    password: '',
    re_password: '',
    email: '',
    role: 0,
    department: {
      id: -1,
      name: '',
    },
    phone: '',
    uid: 0,
  }
  private rules = {
    username: [
      {
        required: true,
        message: this.$t('views.userList.namePlaceholder'),
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: this.$t('views.userList.emailPlaceholder'),
        trigger: 'blur',
      },
    ],
    phone: [
      {
        required: true,
        message: this.$t('views.userList.phonePlaceholder'),
        trigger: 'blur',
      },
    ],
    password: [{ validator: this.validateNewPass, trigger: 'blur' }],
    re_password: [{ validator: this.validateCheckPass, trigger: 'blur' }],
  }

  private validateNewPass(rule: any, value: string, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('views.userList.needPWD') as string))
    } else {
      if (this.userForm.password !== '') {
        ;(this.$refs.ruleForm as Form).validateField('re_password')
      }
      callback()
    }
  }
  private validateCheckPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('views.userList.rePWD') as string))
    } else if (value !== this.userForm.password) {
      callback(new Error(this.$t('views.userList.diffPWD') as string))
    } else {
      callback()
    }
  }

  created() {
    this.getTableData()
    this.departmentList()
  }

  private addDialogShow() {
    this.userForm = {
      username: '',
      password: '',
      re_password: '',
      email: '',
      role: 0,
      department: {
        id: -1,
        name: '',
      },
      phone: '',
      uid: 0,
    }
    this.addDialogOpen = true
  }

  private userEdit(row: UserListObj) {
    this.userForm = {
      username: row.username,
      password: '',
      re_password: '',
      email: row.email,
      role: row.is_superuser ? 1 : 0,
      department: row.department,
      phone: row.phone,
      uid: row.id,
    }
    this.addDialogOpen = true
  }

  private cancelAdd() {
    this.addDialogOpen = false
  }
  private newSelectData() {
    this.page = 1
    this.getTableData()
  }
  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  private async getTableData() {
    const params: {
      page: number
      pageSize: number
      keywords?: string
    } = {
      page: this.page,
      pageSize: this.pageSize,
    }
    if (this.keywords) {
      params.keywords = this.keywords
    }
    this.loadingStart()
    const { status, msg, data, total } = await this.services.user.userList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data
    this.total = total
  }

  private async departmentList() {
    this.loadingStart()
    const { status, msg, data } = await this.services.user.departmentList()
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.departments = data
  }

  private userAdd() {
    ;(this.$refs.ruleForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params: UserAddParams = {
          username: this.userForm.username,
          password: this.userForm.password,
          re_password: this.userForm.re_password,
          email: this.userForm.email,
          role: this.userForm.role,
          department: this.userForm.department,
          phone: this.userForm.phone,
        }
        if (this.userForm.uid) {
          params.uid = this.userForm.uid
        }
        this.loadingStart()
        const { status, msg } = await this.services.user.userAdd(params)
        this.loadingDone()
        if (status !== 201) {
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        }
        this.addDialogOpen = false
        this.getTableData()
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  private async userDelete(uid: number) {
    this.loadingStart()
    const { status, msg } = await this.services.user.userDelete({
      uid,
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTableData()
  }
}
